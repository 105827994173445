import React, { useEffect, useState } from "react";
import StudentHeader from "../../student/studentHeader";
import { Link } from "react-router-dom";
import "./index.css";
import { Checkbox, ConfigProvider, Modal, Tabs, Tooltip } from "antd";
import Result from "./Result";
import { useParams, useNavigate, useLocation } from "react-router";
import axios from "../../../api/axios";
import { LogOut, Moon, Star, User, Delete, Download } from "react-feather";
import StudentTestResultDownload from "../addStudentTestresultDownload";
import axiosInstance from "../../../api/axios1";

const StudentTestResult = () => {
  const onChange = (key) => {};
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = window.location.origin;
  // console.log(currentPath,"path")

  const [attempt, setattempt] = useState("");
  const [data, setdata] = useState([]);
  const token = localStorage.getItem("tokenStudent");
  const [isModalVisibleForm1, setIsModalVisibleForm1] = useState(false);
  const [result, setResult] = useState(false);
  const [resultanalysis, setResultAnalysis] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const[moduleid,setModuleId]=useState([])


  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const Attempt = async () => {
    const responce = await axios.get(`api/student/test-attempts/${id}`, config);
    // console.log(responce)
    setattempt(responce.data.attempt);
    setdata(responce?.data?.fk_user_id);
    setModuleId(responce?.data?.fk_sub_module_id)

  };
  useEffect(() => {
    Attempt();
  }, []);
  const MultiResult = async () => {
    const responce = await axiosInstance.get(
      `api/testPdfResult/${moduleid}/${selectedButton+1}`,
      config
    );
    //  console.log(responce, "responce");
    window.open(responce?.data,"_blank")

    // setattempt(responce.data.attempt);
    // setdata(responce?.data?.fk_user_id);
  };
  const MultiAnwer = async () => {
    const responce = await axiosInstance.get(
      `api/testpdfresultallquestion/${moduleid}/${selectedButton+1}`,
      config
    );
    //  console.log(responce, "responce");
    window.open(responce?.data,"_blank")

    // setattempt(responce.data.attempt);
    // setdata(responce?.data?.fk_user_id);
  };
  // useEffect(() => {
  //   MultiResult();
  //   MultiAnwer();
  // }, []);
  const [selectedButton, setSelectedButton] = useState(0);
  const handleClick = (index) => {
    // console.log(index,"index")
    setSelectedButton(index);
  };
  // console.log(selectedButton,"button")
  const handleModalDeleteClose = () => {
    setIsModalVisibleForm1(false);
  };
  const handleShowForm1 = () => {
    setIsModalVisibleForm1(true);
  };
  const handleCreate = (index) => {
    setSelectedButton(index);

    handleShowForm1();
  };
  const onChange1 = (e) => {
    setChecked(e.target.checked);
    setChecked1(false);
    setChecked2(false);
  };

  const onChange2 = (e) => {
    setChecked(false);
    setChecked1(e.target.checked);
    setChecked2(false);
  };

  const onChange3 = (e) => {
    setChecked(false);
    setChecked1(false);
    setChecked2(e.target.checked);
  };
  const handleDownloadClick = () => {
    //  navigate(`/student/test-series-result-single/downlaod/${data}/${id}/${selectedButton+1}`)
    var downloadUrl = "";

    if (checked == true ) 
     {
      // console.log("krishna")
    //   downloadUrl = `${currentPath}/student/test-series-result-single/downlaod/result/${data}/${id}/${
    //     selectedButton + 1
    //   }`;
    MultiResult();
      // console.log(checked,"",checked1,"",checked2)
    } 
    else if(checked2==true)
    {
      // console.log("krishna11")

      // downloadUrl=`${currentPath}/student/test-series-result-single/AnswerSheet/${data}/${id}/${
      //   selectedButton + 1
      // }`;
      MultiAnwer();
    }
   
  

    // console.log("Download URL:", downloadUrl);
    // Now you can use `downloadUrl` as needed, for example, make a request to download the file without navigating
    handleModalDeleteClose();
  };
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={"Grid2"} />
        {/* BreadCrumb */}
        <div className="breadcrumb-bar p-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/student/test-series">Test Series</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <Link>Test Result</Link>
                      </li>
                      {/* <li className="breadcrumb-item">Students Videos</li> */}
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BreadCrumb */}

        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "inline-flex",
              border: ".4px solid #c0bbbb",
              borderRadius: "16px",
              padding: "12px",
              overflow:"auto"
            }}
          >
            {Array.from({ length: attempt }, (_, index) => (
              <div
                className="row"
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                }}
              >
                <div
                  className="col-12"
                  style={{
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    //  border:".4px solid #c0bbbb",
                    //  borderRadius: "4px",
                     marginLeft:`${index==0?"3px":"10px"}`
                  }}
                >
                  <button
                    className="btn btn-primary value"
                    style={{
                      marginLeft: "20px",
                      borderRadius: "20px",
                      backgroundColor:
                        selectedButton === index ? "#FF875A" : "white",
                      color: selectedButton === index ? "white" : "black",
                      margin: "auto",
                      border: "none",
                      // width:"150px",
                    }}
                    onClick={() => handleClick(index)}
                  >
                    Attempt {index + 1}
                  </button>
                  <div style={{ width: "50px",justifyContent:"center",display:"flex" }}>
                    <Tooltip title={"Download Pdf"}>
                      <Link
                        className=""
                        onClick={() => {
                          handleCreate(index);
                        }}
                      >
                        <Download
                          size={24}
                          color={"#FF875A"}
                          className="headerIcon"
                        />{" "}
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
        <Modal
      title="Download Pdf"
      okButtonProps={{
        style: { backgroundColor: "#FF875A" },
        children: "Import",
      }}
      open={isModalVisibleForm1}
      okText="Download"
      onOk={handleDownloadClick}
      onCancel={handleModalDeleteClose}
    >
      <div>
        <Checkbox onChange={onChange1} checked={checked}>
          Result
        </Checkbox>
      </div>

      {/* <div>
        <Checkbox onChange={onChange2} checked={checked1}>
          Result with Analysis
        </Checkbox>
      </div> */}

      <div>
        <Checkbox onChange={onChange3} checked={checked2}>
          Answer Sheet
        </Checkbox>
      </div>
    </Modal>

        </div>

        <Result attempt={selectedButton + 1} data={data?.fk_user_id} />

        {/* Page Wrapper */}
        {/* <Footer /> */}
      </div>
      {/* <div style={{ display: "none" }}>
        <StudentTestResultDownload attempt={attempt} userid={data} />
      </div> */}
    </>
  );
};

export default StudentTestResult;
