import React, { useEffect, useState } from "react";
import moment from "moment";
import { Input, Space } from "antd";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import StudentInstructorSidebar from "../../instructor/studentSidebar";
import { Link, useNavigate } from "react-router-dom";
import StudentHeader from "../studentHeader";
import CryptoJS from "crypto-js";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../../footer";
import { User11, Icon1, Icon2, Certification } from "../../imagepath";
import axios from "../../../api/axios";
import "./index.css";
import { notification, } from "antd";

const VIEW = "/api/student-liveclass";
const STATIS = "/api/live-classes/status";

import PaginationStudent from "../../pages/course/addCourse/PaginationStudentSub";
import FooterStudent from "../../fotterstudent";
import axiosInstance from "../../../api/axios1";

export default function StudentLiveClasses() {
  const { Search } = Input;
  localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

  const [page, setPage] = useState(1);
  const [list, setList] = useState(null);
  const [uniq_batch_relation, setuniq_batch_relation] = useState(null)
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [last_page, setLast_page] = useState(1);
  const handleClose = () => setShow(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoomurl, setZoomurl] = useState()
  const handleCloseModule = () => setShowAdd(false);
  const handleCloseModuleEdit = () => setShowEdit(false);

  const navigate = useNavigate();
  const pageSize = 10;
  const itemsPerPage=10;
  const indexOfLastCourse = currentPage * 10;
  const indexOfFirstCourse = indexOfLastCourse - 10;
  const visiblebatchData = list?.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );
  // console.log(visiblebatchData,)
  const batchDataLength = list?.length ? list?.length : 0;
  const numOFTotalPage = Math.ceil(batchDataLength / 10);
  const pages = [...Array(numOFTotalPage + 1).keys()].slice(1);

  const handleMeeting = (id, meeting_url, expiry_date) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      "my-secret-key@admin8623009959"
    ).toString();
    let ciphertexts = encodeURIComponent(ciphertext);
    let date = new Date();
    let current = moment(date).format("YYYY-MM-DD HH:mm:ss").valueOf();
    let expiry_dates = moment(expiry_date)
      .format("YYYY-MM-DD HH:mm:ss")
      .valueOf();
    // console.log(`${current}     ${expiry_dates}`);
    if (current > expiry_dates) {
      toast.info("Course is Expired ");
    } else {
      navigate(`/student/std-live-class-join/${ciphertexts}`);
    }
    // window.open(meeting_url, '_blank')
  };
  const handleJoinBtn = (id,meeting_url,expiry_date)=>{
    if(zoomurl == 0){
      handleMeeting(id, meeting_url, expiry_date)
    }else{
      window.location.href = meeting_url
    }
   
  }
  const token = localStorage.getItem("tokenStudent");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const fetchApis = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance .get(VIEW, config);
      // console.log(response,"res")
      // setLast_page(response?.data?.last_page)
      const course = response?.data[0]?.map((e) => e.course).flat()
      setZoomurl(response?.data[1].join_from_zoom)
  //  console.log(course,"course") 
  const coursesWithLiveClass = course.filter(e => e.course_relation_liveclass.length > 0);
          const coursebatchrelation =coursesWithLiveClass?.map((e)=>{
            const cname=e.name
            const coursebatchrelation=[]
            e?.course_relation_liveclass?.map((r)=>{
              coursebatchrelation?.push(Object.assign(r,{cname:cname}))
            })
            return coursebatchrelation
          }).flat()
            const batch_detail_relation =  coursebatchrelation?.map((e)=>{
              const cname=e.cname
              const batch_detail_relation=[]
              e?.livevideocourserelation?.map((r)=>{
                batch_detail_relation.push(Object.assign(r,{cname:cname}))
              })
              return batch_detail_relation
            }).flat()
            const  batch_relation=batch_detail_relation?.map((e)=>{
              const cname =e.cname
              const batch_relation  =[]
              e?.liveclassvideo?.map((r)=>{
                batch_relation.push(Object.assign(r,{cname:cname}))
              })
              return batch_relation
            }).flat()
            const getUniqueData = (data) => {
              const uniqueIds = new Set();
              return data.filter((item) => {
                if (!uniqueIds.has(item.id)) {
                  uniqueIds.add(item.id);
                  return true;
                }
                return false;
              });
            };
            
            const uniqueData = getUniqueData(batch_relation);
            setList(uniqueData);
              
//           }
         
//  })

// console.log("krihna")

    
        // console.log(liveclassbatchvideo);

      // const course = response?.data?.map((e) => {
      //   const expiry_date = e?.expiry_date
      //   const courses = []
      //   e?.course?.map((cou) => {
      //     courses?.push(Object.assign(cou, { expiry_date: expiry_date }));
      //   })
      //   return courses
      // }).flat()
      // const batch = []
      // const course_relation_liveclass = course?.map((e) => {
      //   const expiry_date = e?.expiry_date
      //   const cname = e?.name
      //   const relation_liveclass = []
      //   e?.coursebatchrelation?.map((bat) => {
      //     batch.push(bat)
      //   })
      //   e?.course_relation_liveclass?.map((rel) => {
      //     relation_liveclass?.push(Object.assign(rel, { expiry_date: expiry_date, cname: cname }));
      //   })
      //   return relation_liveclass
      // })
      // const batch_details_relation = batch?.map((e) => {
      //   const course_id = e.course_id
      //   const batch_details_relation = []
      //   e?.batch_details_relation.map((bat) => {
      //     batch_details_relation.push(Object.assign(bat, { course_id: course_id }))
      //   })
      //   return batch_details_relation
      // }).flat()
      // const batch_relation = batch_details_relation?.map((e) => {
      //   const course_id = e.course_id
      //   const batch_relation = []
      //   e?.batch_relation.map((bat) => {
      //     batch_relation.push(Object.assign(bat, { course_id: course_id }))
      //   })
      //   return batch_relation
      // }).flat()
      // const uniq = (arr, track = new Set()) =>
      //   arr.filter(({ id }) => (track.has(id) ? false : track.add(id)));
      // const uniq_batch_relation = batch_relation.map(({ id, name, course_id }) => ({ sub_id: id, batch: name, course_id }))
      // const coursebatchrelation = course_relation_liveclass?.flat()
      // const coursebatchrelationFilter = coursebatchrelation?.filter((e) => e?.livevideocourserelation)
      // const livevideocourserelation = coursebatchrelationFilter?.map((e) => {
      //   const expiry_date = e?.expiry_date
      //   const cname = e?.cname
      //   const course_id = e?.course_id
      //   const livevideocourserelation = []
      //   e?.livevideocourserelation?.map((liv) => {
      //     livevideocourserelation?.push(Object.assign(liv, { expiry_date: expiry_date, cname: cname, course_id: course_id }))
      //   })
      //   return livevideocourserelation
      // }).flat()
      // const liveclassvideos = livevideocourserelation?.map((e) => {
      //   const expiry_date = e?.expiry_date
      //   const cname = e?.cname
      //   const course_id = e?.course_id
      //   const sub_module_id = e?.sub_module_id
      //   const liveclassvideo = []
      //   e?.liveclassvideo?.map((classlive) => {
      //     liveclassvideo?.push(Object.assign(classlive, { expiry_date: expiry_date, cname: cname, sub_module_id: sub_module_id, course_id }))
      //   })
      //   return liveclassvideo
      // }).flat()
      // const liveclassvideosfilter = [...new Set(liveclassvideos.map((e) => e.cname))]
      // setuniq_batch_relation(uniq_batch_relation)
      // const expectedArray = []
      // liveclassvideos?.map((e) => {
      //   uniq_batch_relation?.map((d) => {
      //     if (e.course_id == d.course_id) {
      //       expectedArray?.push(Object.assign({}, e, d))
      //     }
      //   })
      // })

      // const unique = [];
      // liveclassvideos.map(x => unique.filter(a => a.cname == x.cname && a.title == x.title).length > 0 ? null : unique.push(x));
      // console.log(liveclassbatchvideo,"videos")
   
      // const meeting_id = [...new Set(unique.map((e) => {
      //   return e.meeting_id
      // }))]

      // setLast_page(Math.ceil(liveclassbatchvideo?.length / pageSize));
      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem('tokenStudent')
        navigate('/login')
      } else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
      openNotificationWithIcon3("error".error?.response?.data?.messgae)
      }
      setLoading(false);
    }
  };
  // console.log(list,"list")
  useEffect(() => {
    fetchApis();
  }, []);
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };


  const openNotificationWithIcon2 = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "red" }}>
                <strong>Internal Server Error </strong>
              </div>
            ),
          duration: 1
        });
  };
  useEffect(() => {
    const lastVisiteStudentRoute = localStorage.getItem("lastVisiteStudentRoute")
    if (lastVisiteStudentRoute) {
      localStorage.removeItem("lastVisiteStudentRoute")
    }
    else {
      localStorage.setItem("lastVisiteStudentRoute", window.location.pathname);

    }
  }, []);
  const renderPagination = () => {
    const pagesToShow = 6;
    const totalPageCount = Math.ceil(batchDataLength / itemsPerPage);

    if (totalPageCount <= pagesToShow) {
      return [...Array(totalPageCount).keys()]?.map(pageNum => (
        <li
          key={pageNum}
          className={currentPage === pageNum + 1 ? "page-item active" : "page-item"}
        >
          <Link
            className="page-link"
            to="#"
            onClick={() => setCurrentPage(pageNum + 1)}
          >
            {pageNum + 1}
          </Link>
        </li>
      ));
    } else {
      if (currentPage <= 3) {
        for (let i = 0; i < 5; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      } else if (currentPage >= totalPageCount - 2) {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = totalPageCount - 5; i < totalPageCount; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i + 1 ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Link>
            </li>
          );
        }
      } else {
        pages.push(
          <li
            key={0}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(1)}
            >
              1
            </Link>
          </li>
        );
        pages?.push(<li key="ellipsis1" className="page-item disabled"><span className="page-link">...</span></li>);
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pages.push(
            <li
              key={i}
              className={currentPage === i ? "page-item active" : "page-item"}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => setCurrentPage(i)}
              >
                {i}
              </Link>
            </li>
          );
        }
        pages.push(<li key="ellipsis2" className="page-item disabled"><span className="page-link">...</span></li>);
        pages.push(
          <li
            key={totalPageCount - 1}
            className="page-item"
          >
            <Link
              className="page-link"
              to="#"
              onClick={() => setCurrentPage(totalPageCount)}
            >
              {totalPageCount}
            </Link>
          </li>
        );
      }
      return pages;
    }
  };
  // console.log(list,"list")
  return (
    <div className="main-wrapper">
      <StudentHeader />
      <div className="breadcrumb-bar">
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <Link to="/student/std-live-class">Live Classes</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container"
          style={{ minWidth: "98%", margin: "auto" }}

        >
          <div className="row">
            {/* Sidebar */}
            {/* / <StudentInstructorSidebar activeMenu={"LiveClasses"} /> */}
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div
              className="col-xl-9 col-lg-8 col-md-12"
              style={{ width: "100%" }}
            >
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mb-3 mb-md-4 mb-lg-5 ">Live Classes</h3>
                    {/* animate__animated animate__fadeIn live-classes-heading */}
                    {/* <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="comman-space pb-0">
                          <div className="settings-referral-blk table-responsive">
                            <table
                              className="table table-nowrap"
                              style={{ marginTop: "15px" }}
                            >
                              <thead>
                                <tr className="text-center">
                                  <th>SR. NO.</th>
                                  <th>COURSE NAME</th>
                                  <th>MEETING TITLE</th>
                                  <th>START </th>
                                  <th>BATCH</th>
                                  <th>ACTIONS</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              {loading === true ? (
                                <div
                                  class="d-flex justify-content-center"
                                  style={{ position: "absolute" }}
                                >
                                  <div
                                    class="spinner-border text-warning"
                                    style={{
                                      left: "50%",
                                      marginLeft: "24em",
                                      marginTop: "10em",
                                    }}
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              ) : (
                                <tbody>
                                  {list?.length > 0 ? list
                                    ?.slice(
                                      (page - 1) * pageSize,
                                      page * pageSize
                                    )
                                    .map((item, i) => {
                                      const currentDate = moment();
                                      const startDate = moment(item.start);
                                      const endDate = moment(item.end);
                                      let formattedStartDate;
                                      let formattedEndDate;

                                      if (
                                        startDate.isSame(currentDate, "day")
                                      ) {
                                        formattedStartDate = "Today";
                                      } else if (
                                        startDate.isSame(
                                          currentDate.clone().add(1, "day"),
                                          "day"
                                        )
                                      ) {
                                        formattedStartDate = "Tomorrow";
                                      } else {
                                        formattedStartDate = `${startDate.format(
                                          "DD MMMM YYYY"
                                        )}`;
                                      }
                                      const srNo =
                                        (page - 1) * pageSize + i + 1;

                                      return (
                                        <tr
                                          className="text-center"
                                          key={item.id}
                                        >
                                          <td>{srNo}</td>
                                          <td>{item.cname}</td>
                                          <td>{`${item.title.slice(0, 15)} ${item.title.length > 15 ? "..." : ""
                                            }`}</td>
                                          <td>
                                            {" "}
                                            <span>{`${formattedStartDate} ${moment(
                                              item.start
                                            ).format("hh:mm A")}`}</span>
                                          </td>
                                          <td>

                                            {uniq_batch_relation?.map((e) => {
                                              if (e.course_id == item.course_id) {
                                                return <span
                                                  className="badge badge-pill badge-primary"
                                                  style={{
                                                    marginRight: ".25em",
                                                  }}
                                                >{e.batch}
                                                </span>
                                              }
                                            })}

                                          </td>
                                          <td>
                                            <button
                                              type="button"
                                              className="btn btn-secondary"
                                              onClick={() =>
                                                handleMeeting(
                                                  item.id,
                                                  item.meeting_url,
                                                  item.expiry_date
                                                )
                                              }
                                            >
                                              Join
                                            </button>{" "}
                                          </td>
                                        </tr>
                                      );
                                    }) : <div
                                      style={{
                                        left: "50%",
                                        marginLeft: "27em",
                                        marginTop: "4em",
                                      }}
                                    >
                                    {" "}
                                    <h2 className="text-center">No Live Classes</h2>
                                  </div>}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
             
                    </div> */}
                    {loading === true ?
                      <div
                        style={{ display: 'block' }}
                        id="cover-spin"
                      >
                      </div>
                      :
                      <div className="row">
                        {visiblebatchData?.length > 0 ? visiblebatchData
                          // ?.slice(
                          //   (page - 1) * pageSize,
                          //   page * pageSize
                          // )
                          .map((item, index ,i) => {
                            //  console.log(index,"item")
                            const currentDate = moment();
                            const startDate = moment(item.start);
                            const endDate = moment(item.end);
                            let formattedStartDate;
                            let formattedEndDate;

                            if (
                              startDate.isSame(currentDate, "day")
                            ) {
                              formattedStartDate = "Today";
                            } else if (
                              startDate.isSame(
                                currentDate.clone().add(1, "day"),
                                "day"
                              )
                            ) {
                              formattedStartDate = "Tomorrow";
                            } else {
                              formattedStartDate = `${startDate.format(
                                "DD MMMM YYYY"
                              )}`;
                            }
                            const srNo =
                              (page - 1) * pageSize + i + 1;

                            return (
                              <div key={index}>         
                              <div className="col-lg-12 d-flex" >
                               
                                <div className="instructor-list flex-fill">
                                  <div className="instructor-img">
                                    <Link >
                                      <img className="img-fluid" alt="" src={Certification} />
                                    </Link>
                                  </div>
                                  <div className="instructor-content">
                                    <h5>
                                      <Link >COURSE NAME: {item.cname}</Link>
                                    </h5>
                                    <h6>MEETING TITLE: {`${item.title.slice(0, 15)} ${item.title.length > 15 ? "..." : ""
                                      }`}</h6>
                                    <div className="instructor-info">
                                      <div className="course-view d-flex align-items-center ms-0">
                                        <img src={Icon2} className="me-1" alt="" />
                                        <p>  <span>{`${formattedStartDate} ${moment(
                                          item.start
                                        ).format("hh:mm A")}`}</span></p>
                                      </div>
                                      <a className="rating-count">
                                        <button
                                          type="button"
                                          className="btn  text-white"
                                          style={{ background: '#392C7D', padding: '10px 25px' }}
                                          onClick={() =>
                                            handleJoinBtn(
                                              item.id,
                                              item.meeting_url,
                                              item.expiry_date
                                            )
                                          }
                                        >
                                          Join
                                        </button>{" "}
                                      </a>
                                    </div>
                                    <div className="instructor-badge">
                                    <span style={{ margin: '.15em' }} className="web-badge">{item.batch}</span>
                                      {/* {uniq_batch_relation?.map((e) => {
                                        if (e.course_id == item.course_id) {
                                          return <span style={{ margin: '.15em' }} className="web-badge">{e.batch}</span>
                                        }
                                      })} */}

                                    </div>
                                  </div>
                                </div>
                              </div></div>
                            );
                          }) : <div
                            style={{
                              // left: "50%",
                              // marginLeft: "27em",
                              // marginTop: "4em",
                            }}
                          >
                          {" "}
                          <h2 className="text-center"
                          style={{paddingBottom:"20px"}}
                          >No Live Classes</h2>
                        </div>}

                      </div>}


                  </div>
                </div>
              </div>
              {/* Instructor Dashboard */}
            </div>
          </div>
          {/* Add pagination component or links here */}
         {visiblebatchData?.length>0? <div className="fixtable"
          style={{marginBottom:"20px"}}
          >
          {/* <PaginationStudent
           nPages={last_page}
           currentPage={currentPage}
           setCurrentPage={setCurrentPage}
           link={VIEW}
           setData={setList}
          
          /> */}
                         <ul className="pagination lms-page d-flex align-items-center justify-content-center" style={{ marginLeft: '1.5em' }}>
                                                        <li className="page-item prev" onClick={() => prevPageHandler()}>
                                                            <Link className="page-link" to="#" tabIndex={-1}>
                                                                <i className="fas fa-angle-left" />
                                                            </Link>
                                                        </li>
                                                       {renderPagination()}

                                                        <li className="page-item next" onClick={() => nextPageHandler()}>
                                                            <Link className="page-link" to="#">
                                                                <i className="fas fa-angle-right" />
                                                            </Link>
                                                        </li>
                                                    </ul>
          </div>:""}
         
       
        </div>
        <ToastContainer />
        <FooterStudent />
      </div>
    </div>
  );
}
