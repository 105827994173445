import React, { useState, useEffect } from "react";
import AdminHeader from "../../instructor/adminHeader";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Button, Modal, Select } from "antd";
import axios from "../../../api/axios";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { FaFolder, FaPlay } from "react-icons/fa";
import "./index.css";
const VIMEOSUBMODUULE = "api/recorded-video/submodule-list";
const COURSEVIDEO = "api/admin/course/active";
const VIDEOMODULE = "api/recorded-video/module-list";
const VIMEOIMPORT = "api/admin/vimeo/import/category";
const SWITCHUSERS = "api/admin/vimeo_accounts/switch";
const VIMEOSELECT = "api/admin/vimeo_accounts/current";
const VIMEOUSERS = "/api/admin/vimeo_Account";
export default function GumletVimeo() {
  const [loading, setLoading] = useState(false);
  const [tableLoad,setTableLoad] = useState(false)
  const [token, setToken] = useState();
  const [subid, setsubid] = useState("");
  const [modSubModValue, setModSubModValue] = useState();
  const [modSubMod, setModSubMod] = useState([]);
  const [value, setValue] = useState("");
  const [collection, setCollection] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [currentImportDir, setCurrentImportDir] = useState(null);
  const [playlist, setPlaylist] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [video, setVideo] = useState([]);
  const [pagination, setPagination] = useState({ total: 0, offset: 0 });
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [videoURL, setVideoURL] = useState(null)
  const PAGE_LIMIT = 10;
  const [submodule, setsubmodule] = useState(null);
  const [course, setCourses] = useState([]);
  const [video1, setVideo1] = useState([]);
  const [selectedMod, setSelectedMod] = useState();
  const [selectedSubMod, setSelectedSubMod] = useState();
  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    if (token) fetchCollection();
  }, [token]);

  const fetchToken = async () => {
    let get = await axios.get(`api/admin/gumlet/token`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setToken(get?.data);
  };

  const fetchCollection = async () => {
    setTableLoad(true)
    let get = await axios.get(`api/admin/gumlet/collections`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setCollection(get?.data.message.data);
    setTableLoad(false)
  };

  const fetchPlaylist = async (id) => {
    setTableLoad(true)
    let get = await axios.get(`api/admin/gumlet/playlists/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setPlaylist(get?.data.message);
    setTableLoad(false)
  };

  const fetchVideos = async (collectionId, id, page = 1) => {
    setTableLoad(true)
    let get = await axios.get(
      `api/admin/gumlet/videos/collection/${collectionId}/playlist/${id}/${page}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    setVideo(get?.data.message.data);
    setPagination({
      total: get?.data.message?.pagination.total_asset_count,
      offset: get?.data.message?.pagination.current_offset,
    });
    setTableLoad(false)
  };

  const handlePlaylist = async (id,name) => {
    setCurrentTab(1);
    fetchPlaylist(id);
    if (collection.length !== 0) {
      let fil = collection.filter((item) => item.id === id);
      let currentData = {
        id: fil[0].id,
        name: fil[0].name,
      };
      localStorage.setItem(
        'currectGumletCollection',
        JSON.stringify(currentData)
      );
    }
  };

  const handleVideos = async (id, page = 1,name) => {
    setCurrentTab(2);
    setCurrentImportDir(null)
    if (playlist.length !== 0) {
      let fil = playlist.filter((item) => item.id === id);
      let currentData = {
        id: fil[0].id,
        name: fil[0].name,
      };
      localStorage.setItem(
        'currectGumletPlaylist',
        JSON.stringify(currentData)
      );
    }
    fetchVideos(
      JSON.parse(localStorage.getItem("currectGumletCollection")).id,
      id,
      page
    );
  };
  const handlePlay = (item) => {
    console.log(item);
    setOpen(!open);
    setCurrentVideo(item);
  };
  useEffect(() => {
    setCurrentTab(0);
    fetchCollection();
  }, [token]);
  const handleNext = () => {
    handleRemoveSelectedRow();
    if (pagination.offset + PAGE_LIMIT < pagination.total) {
      setPagination({
        total: pagination.total,
        offset: pagination.offset + PAGE_LIMIT,
      });
      handleVideos(
        JSON.parse(localStorage.getItem("currectGumletPlaylist")).id,
        pagination.offset + PAGE_LIMIT,
        JSON.parse(localStorage.getItem("currectGumletPlaylist")).name
      );
    }
  };

  const handlePrev = () => {
    handleRemoveSelectedRow();
    if (pagination.offset > 0) {
      setPagination({
        total: pagination.total,
        offset: Math.max(0, pagination.offset - PAGE_LIMIT),
      });
      handleVideos(JSON.parse(localStorage.getItem('currectGumletPlaylist')).id,Math.max(0, pagination.offset - PAGE_LIMIT),JSON.parse(localStorage.getItem('currectGumletPlaylist')).name)
    }
  };
  const handleAllSelect = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked); // Update state first

    const checkboxes = document.querySelectorAll(".tablecheckbox");
    checkboxes.forEach((checkbox) => (checkbox.checked = isChecked)); // Apply the checked state
  };
  const handleRowSelect = (event) => {
    const isChecked = event.target.checked;
    setIsAllChecked(isChecked);
  };
  const handleRemoveSelectedRow = () => {
    const isChecked = false;
    setIsAllChecked(isChecked);
    const checkboxes = document.querySelectorAll(".tablecheckbox");
    checkboxes.forEach((checkbox) => (checkbox.checked = isChecked));
  };
  const handleAllCheckBoxedItems = async () => {
    setIsModalVisible(!isModalVisible);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const submoduledata = async () => {
    try {
      setLoading(true);

      const response = await axios.get(VIMEOSUBMODUULE, config);
      setsubmodule(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
      setLoading(false);
    }
  };

  const fetchApisCourse = async () => {
    try {
      setLoading(true);

      const response = await axios.get(COURSEVIDEO, config);
      setCourses(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const fetchApisVideo = async () => {
    try {
      setLoading(true);

      const response = await axios.get(VIDEOMODULE, config);
      setVideo1(response?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/admin/login");
      }
    }
  };
  const moduleSubApi = "api/recorded-video/get-submodule";
  // console.log(course, "data");
  const fetchModuleSubMOdules = async (mod) => {
    try {
      setLoading(true);
      const resonse = await axios.post(
        `${moduleSubApi}`,
        { module_id: [mod] },
        config
      );
      setModSubMod(resonse?.data.submodule);
    } catch (error) {
      console.log(error);
      if (error) {
        openNotificationWithIconSesion("error");
        localStorage.removeItem("token");
        navigate("/admin/login");
      } else {
        openNotificationWithIcon2("error", error?.resonse?.data?.message);
      }
    }
  };
  useEffect(() => {
    fetchApisCourse();
    fetchApisVideo();
    submoduledata();
  }, []);

  const handleImport = async () => {
    setLoading(true);
    setIsModalVisible(false);
    const checkedBoxes = document.querySelectorAll(".tablecheckbox:checked");
    const values = Array.from(checkedBoxes).map((checkbox) =>
      checkbox.getAttribute("data-set")
    );
    if (values.length !== 0) {
      let newData = [];
      values.map((item) => {
        let parseData = JSON.parse(item);
        const uri = new URL(
          parseData.thumbnail_url.length === 1
            ? parseData.thumbnail_url[0]
            : parseData.thumbnail_url
        );
        newData.push({
          sub_module_id: selectedSubMod,
          title: parseData.name,
          vid_source: 2,
          video_length: parseData.duration,
          slug: parseData.id,
          description: "gumlet",
          thumbnail: uri.pathname + uri.search,
          thumbnail_path: uri.origin,
          link: parseData.url,
          status: 1,
          watch_time_limit: 9999,
        });
      });
      // sends
      try {
        await axios.post(
          `/api/admin/gumlet/import/videos`,
          {
            data: newData,
            mod: selectedMod,
            submod: selectedSubMod,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "videos imported successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      } catch (e) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "failed to import video",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      }
    }
  };
  const handleCollections = async()=>{
    setCurrentTab(0);
    fetchCollection();
  }

  const importDir = async () => {
    try {
      setLoading(true);
      setIsModalOpen(false);
      await axios.post(`/api/admin/gumlet/import/videos/dir`, {
        collectionId: JSON.parse(
          localStorage.getItem("currectGumletCollection")
        ).id,
        playlistId: currentImportDir,
        mod: selectedMod,
        sub: selectedSubMod,
      },{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "playlist imported successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "failed to import video",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <AdminHeader />
        <div className="row">
          <AdminInstructorSidebar activeMenu={"Videos"} />
          <div className="col-lg-9 col-md-12">
            <div className="card p-3">
              <div className="d-flex justify-content-between">
                <h3>Gumlet Videos</h3>
                <button
                  className="btn btn-danger"
                  onClick={() => navigate(`/admin/gumlet-account`)}
                >
                  Create Gumlet Account
                </button>
              </div>
              <div className="">
                {
                  currentTab === 0 &&
                    <button className="p-2 my-2 btn" disabled={true} style={{color:'#374151'}}>Collections</button>
                }
                {
                  currentTab === 1 &&
                  <div className="d-flex p-2 my-2"  >
                    <button className=" btn" onClick={handleCollections} style={{color:'#374151'}}>Collections</button>
                    <button disabled={true} className=" btn" style={{color:'#374151'}}>/</button>
                    <button disabled={true} className=" btn" style={{color:'#111827'}}>Playlists</button>
                  </div>
                   
                }
                                {
                  currentTab === 2 &&
                  <div className="d-flex p-2 my-2"  >
                    <button className=" btn" onClick={handleCollections} style={{color:'#374151'}}>Collections</button>
                    <button disabled={true} className=" btn" style={{color:'#374151'}}>/</button>
                    <button className=" btn" onClick={(()=>handlePlaylist( JSON.parse(localStorage.getItem('currectGumletCollection')).id))}  style={{color:'#374151'}}>Playlists</button>
                    <button disabled={true} className=" btn" style={{color:'#374151'}}>/</button>
                    <button disabled={true} className=" btn" style={{color:'#111827'}}>Videos</button>
                  </div>
                   
                }
              </div>
              {currentTab === 0 && 
               
              collection.length > 0 ? (
                <div className="row my-4">
                  {collection.map((item, index) => (
                    <div
                      key={index}
                      className="col-md-3 text-center p-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlaylist(item.id,item.name)}
                    >
                      <FaFolder className="text-warning display-1" />
                      <p
                        data-tooltip={item.name}
                        data-tooltip-location="bottom"
                      >
                        {item.name.length > 20
                          ? item.name.slice(0, 20) + "..."
                          : item.name}
                      </p>
                    </div>
                  ))}
                </div>
              ) : currentTab === 1 && 
           
              playlist.length > 0 ? (
                <div className="row my-4">
                  {playlist.map((item, index) => (
                    <div
                      key={index}
                      className="col-md-3 text-center p-2 border rounded-2"
                    >
                      <p
                        className="fw-bold"
                        data-tooltip={item.name}
                        data-tooltip-location="bottom"
                      >
                        {item.name.length > 20
                          ? item.name.slice(0, 20) + "..."
                          : item.name}
                      </p>
                      <div className="d-flex gap-2">
                        <button
                          className="btn btn-secondary w-50"
                          onClick={() => {
                            setIsModalOpen(!isModalOpen);
                            setCurrentImportDir(item.id);
                          }}
                        >
                          Import
                        </button>
                        <button
                          className="btn btn-dark w-50"
                          onClick={() => handleVideos(item.id,1,item.name)}
                        >
                          Videos
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : currentTab === 2 &&
              tableLoad ? <p>Loading....</p> :
              video.length > 0 ? (
                <div className="table-responsive">
                  {isAllChecked && (
                    <div className="flex justify-end items-center">
                      <button
                        disabled={loading}
                        onClick={handleAllCheckBoxedItems}
                        className="px-4 btn btn-primary py-2 mb-1 rounded-xl mr-2 text-white bg-purple-800"
                      >
                        {loading ? "importing..." : "Import Videos"}
                      </button>
                    </div>
                  )}
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={handleAllSelect}
                          />
                        </th>
                        <th>Video</th>
                        <th>Duration</th>
                        <th>Upload On</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {video.map((item, index) => (
                        <tr key={index}>
                          <td>{parseInt(pagination.offset)+ index }</td>
                          <td>
                            <input
                              data-set={JSON.stringify(item)}
                              onChange={handleRowSelect}
                              type="checkbox"
                              className="form-check-input tablecheckbox"
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <img
                                src={item.thumbnail_url}
                                className="img-thumbnail"
                                width="100"
                                alt="thumbnail"
                              />
                              <p className="ms-2">{item.name}</p>
                            </div>
                          </td>
                          <td>
                            {moment
                              .utc(
                                moment
                                  .duration(item.duration, "seconds")
                                  .asMilliseconds()
                              )
                              .format("HH:mm:ss")}
                          </td>
                          <td>{moment(item.created_at).fromNow()}</td>
                          <td className="text-center">
                            <FaPlay
                              className="text-primary fs-4 cursor-pointer"
                              onClick={() => {
                                setIsModalOpen1(!isModalOpen1)
                                setVideoURL(item.id)
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='flex justify-center my-4 items-center'>
                            <div className='flex gap-3 justify-center items-center'>
                            <button
          onClick={handlePrev}
          disabled={pagination.offset === 0}
          className="px-4 py-2 bg-primary text-white rounded-md "
        >
          Previous
        </button>

        <button
          onClick={handleNext}
          disabled={pagination.offset  + PAGE_LIMIT >= pagination.total}
          className="px-4 py-2 bg-primary text-white rounded-md "
        >
          Next
        </button>
                            </div>
                        </div>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
        <Modal
          title="Import Vimeo"
          okButtonProps={{
            style: { backgroundColor: "#FF875A" },
            children: "Import",
          }}
          open={isModalVisible}
          okText="Import"
          onOk={() => {
            handleImport();
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
        >
          <div className="mb-3">
            <label for="formFile" className="form-label">
              Select Category
            </label>
            <Select
              showSearch
              style={{ width: 480 }}
              placeholder="Select a video"
              optionFilterProp="children"
              onChange={(e) => {
                setsubid(e);
              }}
            >
              <Option value={"module"}>{"Module"}</Option>
              <Option value={"sub_module"}>{"sub_module"}</Option>
            </Select>
          </div>
          {subid === "course" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Course
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setValue(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {course?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
            </div>
          ) : (
            <></>
          )}
          {subid === "module" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Module
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setModSubMod([]);
                  setSelectedMod(e);
                  fetchModuleSubMOdules(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {video1?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
              <div className="mb-3">
                <label for="formFile" className="form-label">
                  Select Submodule
                </label>
                <Select
                  showSearch
                  style={{ width: 480 }}
                  placeholder="Select a video"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setSelectedSubMod(e);
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {modSubMod?.map((item) => {
                    return <Option value={item?.id}>{item?.name}</Option>;
                  })}
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
          {subid === "sub_module" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Submodule
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setSelectedSubMod(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {submodule?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
            </div>
          ) : (
            <></>
          )}
        </Modal>

        <Modal
          title="Import Dir"
          okButtonProps={{
            style: { backgroundColor: "#FF875A" },
            children: "Import",
          }}
          open={isModalOpen}
          okText="Import"
          onOk={() => {
            importDir();
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <div className="mb-3">
            <label for="formFile" className="form-label">
              Select Category
            </label>
            <Select
              showSearch
              style={{ width: 480 }}
              placeholder="Select a video"
              optionFilterProp="children"
              onChange={(e) => {
                setsubid(e);
              }}
            >
              <Option value={"module"}>{"Module"}</Option>
              <Option value={"sub_module"}>{"sub_module"}</Option>
            </Select>
          </div>
          {subid === "course" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Course
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setValue(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {course?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
            </div>
          ) : (
            <></>
          )}
          {subid === "module" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Module
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setModSubMod([]);
                  setSelectedMod(e);
                  fetchModuleSubMOdules(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {video1?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
              <div className="mb-3">
                <label for="formFile" className="form-label">
                  Select Submodule
                </label>
                <Select
                  showSearch
                  style={{ width: 480 }}
                  placeholder="Select a video"
                  optionFilterProp="children"
                  onChange={(e) => {
                    setSelectedSubMod(e);
                  }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {modSubMod?.map((item) => {
                    return <Option value={item?.id}>{item?.name}</Option>;
                  })}
                </Select>
              </div>
            </div>
          ) : (
            <></>
          )}
          {subid === "sub_module" ? (
            <div className="mb-3">
              <label for="formFile" className="form-label">
                Select Submodule
              </label>
              <Select
                showSearch
                style={{ width: 480 }}
                placeholder="Select a video"
                optionFilterProp="children"
                onChange={(e) => {
                  setSelectedSubMod(e);
                }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {submodule?.map((item) => {
                  return <Option value={item?.id}>{item?.name}</Option>;
                })}
              </Select>
            </div>
          ) : (
            <></>
          )}
        </Modal>
        <Modal
          title="Play video"
          okButtonProps={{
            style: { backgroundColor: "#FF875A" },
          }}
          open={isModalOpen1}
          onCancel={() => {
            setIsModalOpen1(false);
          }}
        >
          <div  style={{ position: "relative", aspectRatio: "16/9" }}>
      <iframe 
            loading="lazy" title="Gumlet video player"
            src={`https://play.gumlet.io/embed/${videoURL}`}
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;">
          </iframe>
    </div>
          </Modal>
      </div>
    </>
  );
}
