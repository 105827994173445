import React, { useEffect, useRef, useState } from "react";
import { LogOut, Moon, Star, User } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Button } from "react-bootstrap";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {
  Cart,
  Course14,
  Course15,
  Course4,
  logo,
  Messages,
  // Notification,
  User1,
  User11,
  User2,
  User3,
  userprofile,
  Wish,
  ChahalLogo,
} from "../../imagepath";
import Select from "react-select";
import { notification, Modal } from "antd";
import axios from "../../../api/axios";
const LOGOUT = '/api/student/logout'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function AdminHeader({ activeMenu }) {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");
  const name_admin = localStorage.getItem("nameuser");
  const options = [
    { label: "Recorded Videos", value: "/admin/add-video" },
    { label: "Courses", value: "/admin/addcourse" },
    { label: "Students", value: "/admin/adduser" },
    { label: "Live Classes", value: "/admin/live-classes-create" },
  ];
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const [navbar, setNavbar] = useState(false);

  const [showCart, setShowCart] = useState(false);
  const [showWish, setShowWish] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  // Mobile Menu toggle
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu22, setMobileSubMenu22] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  const [mobileSubMenu32, setMobileSubMenu32] = useState(false);
  const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  const [mobileSubMenu42, setMobileSubMenu42] = useState(false);
  const [mobileSubMenu43, setMobileSubMenu43] = useState(false);
  const [mobileSubMenu5, setMobileSubMenu5] = useState(false);

  const openMobileMenu = (event) => {
    document.body.classList.add("menu-opened");
    setMobileMenu(true);
  };
  const hideMobileMenu = (event) => {
    document.body.classList.remove("menu-opened");
    setMobileMenu(false);
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu22 = (e) => {
    e.preventDefault();
    setMobileSubMenu22(!mobileSubMenu22);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  const openMobileSubMenu32 = (e) => {
    e.preventDefault();
    setMobileSubMenu32(!mobileSubMenu32);
  };
  const openMobileSubMenu4 = (e) => {
    e.preventDefault();
    setMobileSubMenu4(!mobileSubMenu4);
  };
  const openMobileSubMenu42 = (e) => {
    e.preventDefault();
    setMobileSubMenu42(!mobileSubMenu42);
  };
  const openMobileSubMenu43 = (e) => {
    e.preventDefault();
    setMobileSubMenu43(!mobileSubMenu43);
  };
  const openMobileSubMenu5 = (e) => {
    e.preventDefault();
    setMobileSubMenu5(!mobileSubMenu5);
  };
  // To close the modal, when clicked outside anywhere
  const cart = useRef();
  useOnClickOutside(cart, () => setShowCart(false));

  const wish = useRef();
  useOnClickOutside(wish, () => setShowWish(false));

  const notificationValue = useRef();
  useOnClickOutside(notificationValue, () => setShowNotification(false));

  const profile = useRef();
  useOnClickOutside(profile, () => setShowProfile(false));

  // Cart Click
  const cartClick = (e) => {
    e.preventDefault();
    // if (showWish) {
    //   setShowWish(false);
    // }
    setShowCart(!showCart);
    console.log(showCart);
  };

  const wishClick = (e) => {
    e.preventDefault();
    // if (showCart) {
    //   setShowCart(false);
    // }
    setShowWish(!showWish);
  };

  const notificationClick = (e) => {
    e.preventDefault();
    setShowNotification(!showNotification);
  };
  const profileClick = (e) => {
    e.preventDefault();
    setShowProfile(!showProfile);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "#FFDEDA",
      border: state.isFocused ? 0 : 0,
      paddingLeft: "5px",
      paddingTop: "5px",
      paddingBottom: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
        color: "black",
      },
      borderRadius: "50px",
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "2px" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  const [api, contextHolder] = notification.useNotification();
  let retrievedObject = JSON.parse(sessionStorage.getItem("default"));

  const openNotificationWithIcon = (type) => {
    // console.log("toast after click")
    notification[type]
      (

        {
          message:
            (
              <div style={{ color: "#52c41a" }}>
                <strong>Logout Successfuly</strong>
              </div>
            ),

          // description:
          // (<div style={{color:"green"}}>
          // Your Batch has Sucessfully Deleted

          // </div>)
          duration: 1
        });
  };
  const logOut = async () => {
    try {
      localStorage.removeItem("token");
      const response = await axios.post(LOGOUT, {}, config);
      if (response) {
        openNotificationWithIcon("success")
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000);
        setAuth({});
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        navigate('/admin/login')
      }
    }
  };

  window.addEventListener("scroll", changeHeaderBackground);
  const handleNew = (e) => {
    navigate(e.value);
    sessionStorage.setItem("default", JSON.stringify(e));
  };
  const handleShow = async (id) => {
    setShow(true); setIsModalVisible(true)
  };
  const handleClose = () => setShow(false);

  return (
    <header className="header header-page">
      <div className="header-fixed">
        <nav
          className={
            navbar
              ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
              : "navbar navbar-expand-lg header-nav scroll-sticky"
          }
        >
          <div className="container "
            style={{ maxWidth: "100%" }}
          >
            <div className="navbar-header"

            >
              <Link
                id="mobile_btn"
                to="#;"
                onClick={openMobileMenu}
              >
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={ChahalLogo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img src={ChahalLogo} className="img-fluid" alt="Logo" />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#;"
                  onClick={hideMobileMenu}
                >
                  <i className="fas fa-times"></i>
                </Link>
              </div>

              {windowDimensions.width <= '991' ? <ul className="main-nav">
                <li className="has-submenu" onClick={() => handleShow()}>
                  <Link className={mobileSubMenu ? "submenu" : ""}>
                    Create New
                  </Link>
                </li>
                <li className="has-submenu"
                                  onClick={hideMobileMenu}

                >
                  <Link to="/admin/batchList">
                    Batches
                  </Link>
                  <ul
                  >
                    <li>
                      <Link to="/admin/batchList">Batches</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                    onClick={hideMobileMenu}

                >
                  <Link to="/admin/course">
                    Courses{" "}
                  </Link>
                </li> 
                {/* <li className="has-submenu">
                  <Link to="/admin/batchList">
                    Batches
                  </Link>
                  <ul
                  >
                    <li>
                      <Link to="/admin/batchList">Batches</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li className="has-submenu">
                  <Link to="/admin/course">
                    Courses{" "}
                  </Link>
                </li> */}
                <li className="has-submenu"
                onClick={hideMobileMenu}

                >
                  <Link to="/admin/recorded-videos-list">
                    Videos{" "}
                  </Link>
                </li>

                <li className="has-submenu"
                  onClick={hideMobileMenu}

                >
                  <Link to="/admin/test-series">
                    Test Series{" "}
                  </Link>
                  <ul
                  >
                    <li>
                      <Link to="/admin/test-series">Test Series</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                onClick={hideMobileMenu}

                >
                  <Link to="/admin/live-classes">
                    Live Classes{" "}
                  </Link>
                  <ul
                  >
                    <li className="has-submenu"
                      onClick={hideMobileMenu}

                    >
                      <Link to="/admin/live-classes">Live Classes</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/student-list">
                    All Students
                  </Link>
                  <ul
                  >
                    <li>
                      <Link to="/admin/student-list"> All Students</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/paid-student">
                    Paid Student
                  </Link>
                  <ul
                  >
                    <li>
                      <Link to="/admin/paid-student">Paid Student</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/exam-allerts">
                    Exam Alerts
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/exam-allerts">Exam Alerts</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/admin_user_logged">
                    User Login Logged
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/admin_user_logged"> User Login Logged</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/centers">
                    Academy  Centers
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/centers">                    Academy  Centers
</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/feeds">
                    Feeds
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/exam-allerts">Exam Alerts</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="/admin/images">
                  Professional Images
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/images">Professional Images</Link>
                    </li>
                  </ul>
                </li>
              <li className="has-submenu"
                              onClick={hideMobileMenu}

              >
                  <Link to="/admin/invoice">
                  Fee Invoice
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/invoice">Fee Invoice</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="">
                  Contact
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/invoice">Fee Invoice</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu"
                                onClick={hideMobileMenu}

                >
                  <Link to="">
                  Transactions 
                  </Link>
                  
                  <ul
                  >
                    <li>
                      <Link to="/admin/transaction">Transactions </Link>
                    </li>
                  
                  </ul>
                </li>
                
                <li onClick={() => logOut()} className="login-link">
                  <Link>Sign Out</Link>
                </li>
              </ul> : ''}
            </div>
            <ul className="nav header-navbar-rht">

              <li className="nav-item user-nav">
                <Link
                  to="#"
                  className={
                    showProfile ? "dropdown-toggle show" : "dropdown-toggle"
                  }
                  data-bs-toggle="dropdown"
                  onClick={profileClick}
                >
                  <span className="user-img">
                    <img src={userprofile} alt="" />
                    <span className="status online"></span>
                  </span>
                </Link>
                <div
                  ref={profile}
                  className={
                    showProfile
                      ? "users dropdown-menu dropdown-menu-right show modalPosition"
                      : "users dropdown-menu dropdown-menu-right"
                  }
                  data-popper-placement="bottom-end"
                >
                  <div className="user-header">
                    <div className="avatar avatar-sm">
                      <img
                        src={userprofile}
                        alt="User Image"
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <div className="user-text">
                      <h6>{name_admin}</h6>
                      <p className="text-muted text mb-0">{name_admin}</p>
                    </div>
                  </div>
                  <Link
                    className="dropdown-item text"
                    to='/admin/profile'
                  >
                    <User size={14} color={"#FF875A"} className="headerIcon" />{" "}
                    Profile
                  </Link>
                  {/* <Link
                    className="dropdown-item text"
                    to="/setting-student-subscription"
                  >
                    <Star size={14} color={"#FF875A"} className="headerIcon" />{" "}
                    Subscription
                  </Link>
                  <div className="dropdown-item text night-mode">
                    <span>
                      <Moon size={14} className="headerIcon" /> Night Mode{" "}
                    </span>
                    <div className="form-check form-switch check-on m-0">
                      <input
                        className="form-check-input focusShadow"
                        type="checkbox"
                        id="night-mode"
                      />
                    </div>
                  </div> */}
                  <a className="dropdown-item text" onClick={() => logOut()} style={{ cursor: "pointer" }}>
                    <LogOut
                      size={14}
                      color={"#FF875A"}
                      className="headerIcon"

                    />{" "}
                    Logout
                  </a>
                </div>
              </li>

            </ul>
          </div>
        </nav>
        <div
          className={mobileMenu ? "sidebar-overlay opened" : "sidebar-overlay"}
        ></div>
        <Modal title="Create New"
          okButtonProps={{ style: { backgroundColor: '#FF875A' }, children: "Import" }}
          open={isModalVisible}
          okText="Create"
          onOk={() => {
            setIsModalVisible(false);

          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
            >
              {/* <Form.Label>Upload File</Form.Label> */}
              <Select
                // className="select2-container"
                options={options}
                defaultValue={retrievedObject}
                // defaultValue={value}
                placeholder="Create New"
                onChange={(e) => {
                  handleNew(e);
                }}
                styles={style}
              ></Select>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Example textarea</Form.Label>
                                  <Form.Control as="textarea" rows={3} /> */}
            </Form.Group>
          </Form>
        </Modal>

        <ToastContainer />
      </div>
    </header>
  );
}
