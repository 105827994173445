import React, { useEffect, useState } from "react";
import "./index.css";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from 'sweetalert2'
import axios from "../../api/axios";
import { useSearchParams } from "react-router-dom";
const DELETEACCOUNT = "/api/student/delete-account";
const DELETEACCOUNTOTP = "/api/student/delete-account/otp";

export default function StudentAccountDelete() {
    const [loading, setLoading] = useState(false)
    const [mod,setMod] = useState(null)
const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [error, setError] = useState(false);
  const [token, setToken] = useState(false);
  const [otp, setOtp] = useState("");
  const handleDeleteClick = () => {
    setShowConfirmModal(true);
  };
  const [searchParams] = useSearchParams();
  const data = searchParams.get("data");
  useEffect(()=>{
    if(!data){
        setLoading(true);
        setMod("token")
    }else{
        setToken(data)
    }
  },[])
  // Proceed to OTP modal
  const handleConfirmDelete = async() => {
   
    setOtpLoading(true);
    try {
        console.log(token)
       await axios.post(DELETEACCOUNTOTP,'',{
        headers:{
            Authorization: `Bearer ${token}`
        }
       });
       Swal.fire({
        position: "top-end",
        icon: "success",
        title: "OTP sent to your registered email",
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      });
      setOtpLoading(false);
      setShowConfirmModal(false);
      setShowOtpModal(true);
    } catch (error) {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: error.response.data.message ?? 'Internal server error',
            showConfirmButton: false,
            timer: 1500,
            width:"18rem",
            didOpen: () => {
              document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
          },
            customClass: {
              popup: "custom-swal-popup"
          }
          });
      setError(error.response.data.message)
      setMod("error")
      setShowConfirmModal(false);
      setShowOtpModal(false);
      setOtpLoading(false);
    }
    
    setOtpLoading(false);
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Submit OTP
  const handleSubmitOtp = async() => {
    setOtpLoading(true);
    try {
      await axios.post(DELETEACCOUNT,{
        otp:otp
      },{
        headers:{
            Authorization: `Bearer ${token}`
        }
       });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your account has been deleted successfully",
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      }).then((res)=>{
        window.location.href = "/";
      });
      setOtpLoading(false);
      setShowOtpModal(false);
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message ?? 'Internal server error',
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      });
      console.log(error);
      setOtpLoading(false);
    }
    
    setOtpLoading(false);
   
  };
  return (
   <>
   <div className="main-wrapper">
        <div className="page-content">
        {
            loading ? 
            mod === "token" ?
            <div className="text-center">
                <h3 className="text-danger">Unauthorized !</h3>
                <p>Login Required</p>
            </div>
            :
            mod === "error" && 
            <div>
                <div className="text-center">
                    <h3 className="text-danger">Error!</h3>
                    <p>{error}</p>
                    </div>
    
            </div>
            :
            <Button variant="danger" className="w-100 btn btn-danger" onClick={handleDeleteClick}>
        Delete Account
      </Button>
        }

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Account Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete your account? This action cannot be undone.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
                <Button variant="danger" onClick={handleConfirmDelete}>Yes, Delete</Button>
              </Modal.Footer>
            </Modal>
      
            {/* OTP Modal */}
            <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Enter OTP</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Enter OTP sent to your email:</Form.Label>
                  <Form.Control type="text" value={otp} onChange={handleOtpChange} placeholder="Enter OTP" />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowOtpModal(false)}>Cancel</Button>
                <Button variant="primary" onClick={handleSubmitOtp}>Submit OTP</Button>
              </Modal.Footer>
            </Modal>
            </div>
            </div>
   
   </>
  )
}
