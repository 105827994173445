import React, { useState, useContext, useEffect } from "react";
import AdminHeader from "../../instructor/adminHeader";
import AdminInstructorSidebar from "../../instructor/adminSidebar";
import { Button, Modal } from 'antd';
import GumletAccountCE from "./GumletAccountCE";
import { Space, Table,Switch  } from 'antd';
import axios from '../../../api/axios';
import Swal from 'sweetalert2';
export default function GumletAccount() {
  const [account, setAccount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelData, setModelData] = useState()
  const [category,setCategory] = useState("create")
  const showModal = () => {
    setIsModalOpen(true);
  };
 
 const getAccounts = async()=>{
      try{
        setLoading(true)
        let get = await axios.get(`/api/admin/gumlet/account/get`,{
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        setLoading(false)
        setAccount(get.data.message);
        if(get.data.message.length > 0){
          let newData = [];
          get.data.message.map((e,index)=>{
            newData.push({
              sr:index+1,
              id:e.id,
              name:e.name,
              default:e.active_account,
              token:e.token

            })
          })
          setAccount(newData);
        }
      }catch(e){
        setLoading(false)
      }

    }
    useEffect(()=>{
      getAccounts();
    },[])
    const onChange = async(item) => {
        try{
          await axios.put(`/api/admin/gumlet/account/default/${item}`,{
            
          },{
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          })
            return Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title:"Default Account updated successfully",
                          showConfirmButton: false,
                          timer: 1500
                        }).then(() => {
                          window.location.reload(); // Reloads the page after the alert
                        });
        }catch(e){
           return Swal.fire({
                         position: "top-end",
                         icon: "error",
                         title:e?.message,
                         showConfirmButton: false,
                         timer: 1500
                       });
        }
    };
    const handleEdit = async(item)=>{
      setCategory("edit")
      setModelData(item)
      setIsModalOpen(!isModalOpen)
    }
    const handleDelete = async(item)=>{
      Swal.fire({
        title: "Do you want to delete account?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then(async(result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          try{
            await axios.delete(`/api/admin/gumlet/account/delete/${item.id}`,{
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            return Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title:"Account deleted successfully",
                          showConfirmButton: false,
                          timer: 1500
                        }).then(() => {
                          window.location.reload(); // Reloads the page after the alert
                        });
          }catch(e){
             return Swal.fire({
                         position: "top-end",
                         icon: "error",
                         title:e?.message,
                         showConfirmButton: false,
                         timer: 1500
                       }).then(() => {
                        window.location.reload(); // Reloads the page after the alert
                      });
          }
        }
      });
    }

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sr',
        key: 'sr',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Default',
        key: 'default',
        dataIndex: 'default',
        render: (_, record) => (
          <>
         {
          record.default == 1 ?
          <Switch  defaultChecked   onChange={(()=>onChange(record.id))} />
          :
          <Switch   onChange={(()=>onChange(record.id))} />
         }
          </>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <button onClick={(()=>handleEdit(record))} className="btn btn-primary">Edit</button>
            <button onClick={(()=>handleDelete(record))} className="btn btn-danger">Delete</button>
          </Space>
        ),
      },
    ];
  return (
    <>
      <div className="main-wrapper ">
        <AdminHeader />
        <div className="page-content ">
          <div
            className="container"
            style={{ maxWidth: "100%", marginTop: "-15px" }}
          ></div>
          <div className="row">
            <AdminInstructorSidebar activeMenu={"Videos"} />
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="tak-instruct-group">
                <div className="row">
                  <div className="col-md-12">
                    <div className="settings-widget">
                      <div className="settings-inner-blk p-0">
                        <div className="sell-course-head comman-space">
                          <div
                            className="d-flex"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="">
                              <h3>Gumlet Account</h3>
                              <nav
                                aria-label="breadcrumb"
                                style={{ marginBottom: "-1.6em" }}
                              >
                                <ol className="breadcrumb">
                                  <li
                                    className="breadcrumb-item "
                                    aria-current="page"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "0.5em",
                                    }}
                                    onClick={() =>
                                      navigate("/admin/recorded-videos-list")
                                    }
                                  >
                                    Recorded Videos
                                  </li>
                                  <li
                                    className="breadcrumb-item active"
                                    aria-current="page"
                                    style={{
                                      cursor: "pointer",
                                      marginTop: "0.5em",
                                    }}
                                  >
                                    Gumlet Account
                                  </li>
                                </ol>
                              </nav>
                            </div>
                            <div
                              className="d-flex justify-content-end"
                              style={{ gap: "8px" }}
                            >
                              <button
                                onClick={(e) => {
                                  setIsModalOpen(!isModalOpen)
                                }}
                                className="btn btn-danger"
                                id="custom-button"
                                style={{ padding: "5px 20px" }}
                                // disabled={checked?.length<=0}
                              >
                                Create Account
                              </button>
                            </div>
                          </div>

                                <div className="my-5">
                                <Table loading={loading} columns={columns} dataSource={account} />
                                </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Create Account" onCancel={() => setIsModalOpen(false)} footer={null} open={isModalOpen}>
       {
        category == "edit" ? 
        <GumletAccountCE type="edit" data={modelData} isOpen={setIsModalOpen} />
        :
        <GumletAccountCE type="create" isOpen={setIsModalOpen} />
       }
      </Modal>
    </>
  );
}
