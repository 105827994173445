import axios from "axios";

const BASEURL ='https://lmsstagecode.stellarflux.com//'

const axiosInstance = axios.create({
  baseURL: BASEURL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tokenStudent");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("tokenStudent");
      localStorage.removeItem("showNotification")
      window.location.href = "/login"; // Redirect to login page
      const keysToRemove = Object.keys(localStorage).filter(key => key.startsWith('notificationClosed_'));
      keysToRemove.forEach(key => localStorage.removeItem(key));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
