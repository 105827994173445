import React, { useEffect, useRef, useState } from "react";
import Footer from "../../footer";
import StudentHeader from "../studentHeader";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import * as Yup from "yup";
import { notification, Space } from "antd";
import axios from "../../../api/axios";
import Select from "react-select";
import "./index.css";
import axiosInstance from "../../../api/axios1";
import { Modal, Button, Form } from "react-bootstrap";
const PROFILEDEATILS = "/api/student/edit-user-profile";
const SAVEPROFILE = "/api/student/edit-user-profile-save";
const UPLOADIMAGE="/api/student/userprofile-image";
const REMOVEIMAGE="/api/remove-profile"
const GETUPLOADIMAGE="/api/student/editImageUserProfile";
const CENTERSLIST = "/api/public/academy_centers";
const DELETEACCOUNT = "/api/student/delete-account";
const DELETEACCOUNTOTP = "/api/student/delete-account/otp";

export default function StudentEditProfiles() {
  const token = localStorage.getItem("tokenStudent");
  const userid = localStorage.getItem("userid");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [userdata, setuserdata] = useState(null);
  const [file, setFile] = useState();
  const [filePre, setFilePre] = useState();
  const [errorI, setErrorI] = useState("");
const[updateimage,setupdateimage]=useState(false)
   const[image,setImage]=useState("")
   const [centers, setCenters] = useState([]);
   const [centersId, setCentersId] = useState("");
 

  //  delete account
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const handleDeleteClick = () => {
    setShowConfirmModal(true);
  };

  // Proceed to OTP modal
  const handleConfirmDelete = async() => {
   
    setOtpLoading(true);
    try {
       await axios.post(DELETEACCOUNTOTP,'',config);
       Swal.fire({
        position: "top-end",
        icon: "success",
        title: "OTP sent to your registered email",
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      });
      setOtpLoading(false);
      setShowConfirmModal(false);
      setShowOtpModal(true);
    } catch (error) {
      console.log(error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message ?? 'Internal server error',
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      });
      setOtpLoading(false);
    }
    
    setOtpLoading(false);
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Submit OTP
  const handleSubmitOtp = async() => {
    setOtpLoading(true);
    try {
      await axios.post(DELETEACCOUNT,{
        otp:otp
      },config);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your account has been deleted successfully",
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      }).then((res)=>{
        window.location.href = "/";
      });
      setOtpLoading(false);
      setShowOtpModal(false);
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message ?? 'Internal server error',
        showConfirmButton: false,
        timer: 1500,
        width:"18rem",
        didOpen: () => {
          document.querySelector(".swal2-popup").style.display = "flex"; // Force flex mode
      },
        customClass: {
          popup: "custom-swal-popup"
      }
      });
      console.log(error);
      setOtpLoading(false);
    }
    
    setOtpLoading(false);
   
  };

const imageRef = useRef(null);

  const userDetails = async () => {
    try {
      const responce = await axiosInstance.get(`${PROFILEDEATILS}/${userid}`, config);
      setuserdata(responce.data);
      setCentersId(responce?.data?.fk_academy_center_id)
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
    }
  };
  useEffect(() => {
    userDetails();
  }, []);
  const initalValue = {
    dob: userdata?.dob?userdata?.dob : "",
    email: userdata?.email?userdata?.email : "",
    gender: userdata?.gender?userdata?.gender : "",
    name: userdata?.name?userdata?.name : "",
    phone: userdata?.phone?userdata?.phone : "",

  };
  
  const Getuploadprofileimage=async()=>{

    try {  
      const response = await axiosInstance.get(`${GETUPLOADIMAGE}/${userid}`, config);
      //  console.log(response,"response in navbar")
      if (response) {
         setImage(response?.data?.image)
         localStorage.setItem("profile-img",response?.data?.image)

      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  useEffect(()=>{
    Getuploadprofileimage();
  },[image])
  // console.log(image,"image")
  const uploadprofileimage=async()=>{

    try {
      const formData= new FormData();
      formData.append("image",file)
      formData.append("userId",userid)
  
      const response = await axiosInstance.post(UPLOADIMAGE,formData, config);
      // console.log(response,"response")
      if (response) {
        Getuploadprofileimage();

        openNotificationWithIcon("success", "Profile Image Updated", "#52c41a");
        localStorage.removeItem("profile-img")
        // localStorage.setItem("profile-img",image)
        if (imageRef.current) imageRef.current.value = "";
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("token");
        navigate("/login");
      }
      else{
        openNotificationWithIcon3("error",error?.response?.data?.message)
      }
    }
  }
  const studentSchema = Yup.object({
    name: Yup.string().min(2).max(100).required("Please enter your name"),
    email: Yup.string().email().required("Please enter your email"),
    phone: Yup.string().min(2).max(10).required("Please enter your Phone Number"),

    dob: Yup.date()
      .min("1969-11-13", "Date is too early")
      .max("2023-11-13", "Date is too further")
      .required("Please Enter date of birth"),
    gender: Yup.string().required("Select your gender"),
  });
  const openNotificationWithIcon = (type, text, col) => {
    notification[type]({
      message: (
        <div style={{ color: col }}>
          <strong>{text}</strong>
        </div>
      ),
      duration: 1,
    });
  };
  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    field,
  } = useFormik({
    initialValues: initalValue,
    enableReinitialize: true,
    onSubmit: async (value) => {
      try {
        const response = await axiosInstance.post(
          `${SAVEPROFILE}`,
          {
            userId: userid,
            dob: value.dob,
            name: value.name,
            gender: value.gender,
            email: value.email,
            phone: value.phone,

          },
          config
        );
        if (response) {
          openNotificationWithIcon("success", "Profile Updated", "#52c41a");
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 500) {
          openNotificationWithIcon("error", "Internal Server Error", "red");
        }
        if (error?.response?.status === 422) {
          openNotificationWithIcon("error", error.response.data.message, "red");
        }
        if (error?.response?.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        }
      }
    },
    validationSchema: studentSchema,
  });
  const today = new Date();
  today.setFullYear(today.getFullYear() - 12);

  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD
const handelProfileRemove=async()=>{
  try {
    const response = await axiosInstance.get(
    
      REMOVEIMAGE,
      config
    );
    if (response) {
      openNotificationWithIcon("success", "Profile Updated", "#52c41a");
      Getuploadprofileimage()
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 500) {
      openNotificationWithIcon("error", "Internal Server Error", "red");
    }
    if (error?.response?.status === 422) {
      openNotificationWithIcon("error", error.response.data.message, "red");
    }
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }
}
useEffect(() => {
  const CentersList = async () => {
    try {
      const response = await axios.get(CENTERSLIST);
      setCenters(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  CentersList();
}, []);
  return (
    <div className="main-wrapper">
      <StudentHeader />
      {/* Student Dashboard */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            {/* <StudentSideBar activeMenu="EditProfile" /> */}
            {/* Sidebar */}

            {/* Profile Details */}
            <div className="col-xl-12 col-md-812 main-page">
              <div className="settings-widget profile-details">
                <div className="settings-menu p-0">
                  <div className="profile-heading">
                    <h3>Edit Profile</h3>
                 
                  </div>
                  {/* <div className="course-group mb-0 d-flex">
                    <div className="course-group-img d-flex align-items-center">
                      <Link to="student-profile">
                        <img src={User11} alt="" className="img-fluid" />
                      </Link>
                      <div className="course-name">
                        <h4>
                          <Link to="student-profile">Your avatar</Link>
                        </h4>
                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                      </div>
                    </div>
                    <div className="profile-share d-flex align-items-center justify-content-center">
                      <Link to="#;" className="btn btn-success">
                        Update
                      </Link>
                      <Link to="#;" className="btn btn-danger">
                        Delete
                      </Link>
                    </div>
                  </div> */}
                  <div className="checkout-form personal-address add-course-info ">
                    {/* <div className="personal-info-head">
                      <h4>Personal Details</h4>
                      <p>Edit your personal information and address.</p>
                    </div> */}
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Name"
                              id="name"
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                            />
                            {errors.name && touched.name ? (
                              <span style={{ color: "red" }}>
                                {errors.name}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">Email</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Your Email Address"
                              id="email"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                            {errors.email && touched.email ? (
                              <span style={{ color: "red" }}>
                                {errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Phone Number
                            </label>

                            <input
                              type="number"
                              name="phone"
                              id="phone"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.phone}
                              onChange={handleChange}
                              // max={maxDate}
                              className="form-control"
                              placeholder="Enter your Phone No"
                              required
                            />
                            {errors.phone && touched.phone ? (
                              <span style={{ color: "red" }}>{errors.phone}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
  <div className="form-group">
    <label className="form-control-label"> Center Name</label>
    <input
      type="text"
      className="form-control"
      value={centers?.find((center) => center?.id == centersId)?.name}
      readOnly
    />
    {/* {errors.fk_center_id && touched.fk_center_id ? (
      <span style={{ color: "red" }}>{errors.fk_center_id}</span>
    ) : null} */}
  </div>
</div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label">
                              Date of Birth
                            </label>

                            <input
                              type="date"
                              name="dob"
                              id="dob"
                              autoCorrect="off"
                              autoComplete="off"
                              value={values.dob}
                              onChange={handleChange}
                              max={maxDate}
                              className="form-control"
                              placeholder="Enter your date of birth"
                              required
                            />
                            {errors.dob && touched.dob ? (
                              <span style={{ color: "red" }}>{errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <label className="text-center">Gender</label>
                              </div>
                            </div>
                            <div style={{ marginTop: "0.5em" }}>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio1"
                                  onChange={getFieldProps("gender").onChange}
                                  value={"0" || values?.gender}
                                  checked={values?.gender === "0"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="inlineRadio2"
                                  checked={values?.gender === "1"}
                                  onChange={getFieldProps("gender").onChange}
                                  value={"1" || values?.gender}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  onChange={getFieldProps("gender").onChange}
                                  id="inlineRadio3"
                                  value={"2" || values?.gender}
                                  checked={values?.gender === "2"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio3"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                            <div>
                              {errors.gender && touched.gender ? (
                                <span style={{ color: "red" }}>
                                  {errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex"
                        style={{gap:"50px"}}
                        >

                        <div className="update-profile"
                        style={{marginTop:"10px"}}
                        >
                          <button className="btn btn-primary">
                            Update Profile
                          </button>
                        </div>
                        <div className="update-profile"
                        style={{marginTop:"10px"}}
                        onClick={handelProfileRemove}
                        >
                          <button className="btn btn-danger">
                            Remove Profile
                          </button>
                        </div>
                        </div>
                      </div>
                    </form>

                
                    {/* <div className="col-lg-6">
                        <div className="">
                          <button className="btn btn-primary"
                          onClick={()=>{
                            setupdateimage(true)
                          }}
                          >
                            upload Image
                          </button>
                        </div>
                        </div> */}
                        <div
                            className="col-lg-12"
                            style={{ marginTop: "15px" }}
                          >
                            <div className="form-group">
                              <label className="form-control-label">
                                Thumbnail
                              </label>
                              <input
                                type="file"
                                name="Thumbnail"
                                id="Thumbnail"
                                onChange={(e) => {
                                  setFile(e.target.files[0]),
                                    setFilePre(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                }}
                                ref={imageRef}
                                className="form-control"
                                placeholder="Cover Image"
                              />
                              {errorI ? (
                                <span style={{ color: "red" }}>{errorI}</span>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="col-lg-12">
                            <div className="form-group">
                              <div
                                className="add-image-box"
                                style={{ minHeight: "0", height: "200px" }}
                              >
                                {!filePre && (
                                  <Link to="#" style={{ fontSize: "200px" }}>
                                    <i className="far fa-image" />
                                  </Link>
                                )}
                                {filePre && (
                                  <img
                                    src={filePre}
                                    style={{
                                      height: "200px",
                                      width: "100%",
                                      borderRadius: "10px",
                                    }}
                                  ></img>
                                )}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-lg-6">
                        <div className="">
                          <button className="btn btn-primary"
                          onClick={()=>{
                            uploadprofileimage()
                          }}
                          >
                            Update Image
                          </button>
                        </div>
                        </div>
                          
                  </div>
                  <div className="col-lg-12 p-2">
                  <Button variant="danger" className="w-100 btn btn-danger" onClick={handleDeleteClick}>
        Delete Account
      </Button>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Account Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete your account? This action cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleConfirmDelete}>Yes, Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* OTP Modal */}
      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Enter OTP sent to your email:</Form.Label>
            <Form.Control type="text" value={otp} onChange={handleOtpChange} placeholder="Enter OTP" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOtpModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmitOtp}>Submit OTP</Button>
        </Modal.Footer>
      </Modal>  
                  </div>
                </div>
              </div>
            </div>
            {/* Profile Details */}
          </div>
        </div>
      </div>
      {/* Student Dashboard */}
      <Footer />
    </div>
  );
}
