import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "../../../api/axios";
import StudentHeader from "../studentHeader";
import VideoJs from "../../pages/course/addCourse/videoJs";
import "./video.css";
const VIEWVIDEO = "/api/student/view-video";
import { notification, Space } from "antd";
import VimeoPlayer1 from "../../pages/course/addCourse/Videoplayerjs";
import axiosInstance from "../../../api/axios1";

export default function StudentView() {
  const { id } = useParams();
  let bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(id),
    "my-secret-key@admin8623009959"
  );

  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");
  const [res, setRes] = useState(null);
  const token = localStorage.getItem("tokenStudent");
  const [linktype, setlinktype] = useState("");
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  function checkUrl(test_url) {
    let testLoc = document.createElement("a");
    testLoc.href = test_url.toLowerCase();
    let url = testLoc.hostname;
    let what;
    if (url.indexOf("youtube.com") !== -1) {
      what = "Youtube";
    } else if (url.indexOf("vimeo.com") !== -1) {
      what = "Vimeo";
    } else {
      what = "None";
    }
    return what;
  }

  const viewVidos = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`${VIEWVIDEO}/${decryptedData}`, config);
      setRes(response.data.res);
      setlinktype(checkUrl(response.data.res.link));
      setUrl(response.data.res.link);
    
      setOtp(response.data.otp);
      setPlaybackInfo(response.data.playbackInfo);
      setLoading(false);
    } catch (error) {
      console.log(error.message)
      console.log(error);
      openNotificationWithIcon2("error");

      if (error?.response?.status === 401) {
        openNotificationWithIconSesion("error")
        localStorage.removeItem("tokenStudent");
        navigate("/login");
      }
      else if (error?.response?.status === 403) {
        localStorage.removeItem("tokenStudent");
        openNotificationWithIcon3("error", error?.response?.data?.message,);
        setTimeout(() => {
          navigate("/login");
        }, 1000); // Delay the navigation to show the notification
      }
      else{
        openNotificationWithIcon2("error")
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    viewVidos();
  }, []);
  const openNotificationWithIcon2 = (type) => {

    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Internal Server Error </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIcon3 = (type,message) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>{message} </strong>
        </div>
      ),
      duration: 1,
    });
  };
  const openNotificationWithIconSesion = (type) => {
    notification[type]({
      message: (
        <div style={{ color: "red" }}>
          <strong>Session Expired Please Login </strong>
        </div>
      ),
      duration: 1,
    });
  };

  const YouTubeGetID = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };
  const ytcode = YouTubeGetID(res?.link);
  let vimeo_Reg = /(videos|video|channels|\.com)\/([\d]+)/;
  const style = {
    videoWrapper: {
      position: "relative",
      paddingBottom: "56.25%",
      paddingTop: "25px",
      height: 0,
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    video: {
      objectFit: "cover",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: "0",
      left: "0",
    },
    video1: {
      objectFit: "cover",
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: "0",
      left: "0",
    },
  };
  useEffect(() => {
    const lastVisiteStudentTest = localStorage.getItem("lastVisiteStudentTest");
    if (lastVisiteStudentTest !== window.location.pathname) {
      localStorage.removeItem("startTime");
      localStorage.removeItem("expirationTime");
    }
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <StudentHeader activeMenu={"videos"} />

        <div className="breadcrumb-bar">
          <div
            className="container"
            style={{ minWidth: "98%", margin: "auto" }}
          >
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {" "}
                        <Link to="/student/videos">Videos</Link>
                      </li>
                      <li
                        className="breadcrumb-item"
                        onClick={() => navigate(-1)}
                      >
                        <Link style={{ textTransform: "capitalize" }}>
                          {res?.title}
                        </Link>{" "}
                      </li>
                      <li className="breadcrumb-item">
                        <Link> Video Details</Link>{" "}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {loading === true ? (
            <div style={{ display: "block" }} id="cover-spin"></div>
          ) : (
            <div className="">
              {res?.vid_source == "0" ? (
                <div
                  // style={{ width: "100%", maxWidth: "85em", margin: "0 auto" }}
                >
                  {linktype == "Youtube" ? (
                    <VideoJs
                      src={`https://www.youtube.com/embed/${ytcode}`}
                      id={ytcode}
                      viewvid={decryptedData}
                      moduleid={res?.video_relation?.sub_module_id}
                      type="video/youtube"
                    />
                  ) : (
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style={{ height: "550px" }}
                    >
                      <h2>We're sorry, something went wrong on our end. Please try again later or contact our support team</h2>
                    </div>
                  )}
                </div>
              ) : res?.vid_source == "1" ? (
                <>
                  <div>
                    <div id="" className="">
                      {linktype == "Vimeo" ? (
                        <VimeoPlayer1
                          id={url}
                          viewvid={decryptedData}
                          moduleid={res?.video_relation?.sub_module_id}
                        />
                      ) : (
                        <div
                          class="d-flex align-items-center justify-content-center"
                          style={{ height: "550px" }}
                        >
                          <h2>We're sorry, something went wrong on our end. Please try again later or contact our support team</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <div >
                  <div  style={{ position: "relative", aspectRatio: "16/9",margin:"3rem" }}>
      <iframe 
            loading="lazy" title="Gumlet video player"
            src={`https://play.gumlet.io/embed/${res?.link?.split('/')[4]}`}
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;">
          </iframe>
    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
