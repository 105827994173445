import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import axios from '../../../api/axios';

export default function GumletAccountCE({
    type,isOpen,data
}) {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(data?.name ? data?.name : undefined);
    const [token, setToken] = useState(data?.token ? data?.token : undefined);
    
   
   
    const handleSubmit = async()=>{
        
        try{

            if(name === undefined){
                return Swal.fire({
                     position: "top-end",
                     icon: "error",
                     title: "Name is required",
                     showConfirmButton: false,
                     timer: 1500
                   });
             }
             if(token === undefined){
                return Swal.fire({
                     position: "top-end",
                     icon: "error",
                     title: "Token is required",
                     showConfirmButton: false,
                     timer: 1500
                   });
             }
             let cr;
             
             const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
              };
             if(type== "create"){
                 cr = await  axios.post(
                         `/api/admin/gumlet/account/create`,
                         {
                           name:name,
                           token,token
                         },
                         config
                       );
             }else if(type == "edit"){
              cr = await  axios.put(
                         `/api/admin/gumlet/account/edit/${data?.id}`,
                         {
                           name:name,
                           token
                         },
                         config
                       );
             }
             isOpen(false)
             return Swal.fire({
                 position: "top-end",
                 icon: "success",
                 title: `Account ${type == "edit" ? "updated" : "created"} successfully`,
                 showConfirmButton: false,
                 timer: 1500
               }).then(() => {
                window.location.reload(); // Reloads the page after the alert
              });
        }catch(e){
            return Swal.fire({
                position: "top-end",
                icon: "error",
                title:e?.message,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                window.location.reload(); // Reloads the page after the alert
              });
        }
    }
  
  return (
    <div>
        <form  >
            <div className="form-group">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" defaultValue={data?.name ? data.name : name} onChange={(e)=>setName(e.target.value)} required/>
            </div>
            <div className="form-group">
                <label className="form-label">Token</label>
                <input type="text" className="form-control" defaultValue={data?.token ? data?.token : token} onChange={(e)=>setToken(e.target.value)} required/>
            </div>
            <button type="button" onClick={handleSubmit} className="btn btn-primary">Submit</button>
        </form>
        <div className="my-5">
          
        </div>
    </div>
  )
}
